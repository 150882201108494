@import '_reset.css';
@import '_fonts.scss';
@import '_swiper.scss';

body {
  font-family: 'Circular Spotify Text', Helvetica, sans-serif;
  height: 100%;
}

.background_fade {
  animation: fade-in 0.6s ease-in-out;
  animation-fill-mode: forwards;
}

.modal-1 {
  display: none;
}

.modal-1.is-open {
  display: block;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* scale up content */
@media (min-width: 1920px) {
  html {
    font-size: 120%;
  }
}

@media (min-width: 2048px) {
  html {
    font-size: 130%;
  }
}

@media (min-width: 2560px) {
  html {
    font-size: 150%;
  }
}

@media (min-width: 3000px) {
  html {
    font-size: 180%;
  }
}

@media (min-width: 3500px) {
  html {
    font-size: 230%;
  }
}

@media (min-width: 4000px) {
  html {
    font-size: 280%;
  }
}
