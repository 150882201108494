@font-face {
  font-family: "Circular Spotify Head";
  font-weight: bold;
  font-style: normal;

  src: url("../assets/fonts/CircularSpotifyHead-Bold.woff2") format("woff2"),
    url("../assets/fonts/CircularSpotifyHead-Bold.woff") format("woff"),
    url("../assets/fonts/CircularSpotifyHead-Bold.svg#CircularSpotifyHead")
      format("svg");
}

@font-face {
  font-family: "Circular Spotify Text";
  font-weight: bold;
  font-style: normal;

  src: url("../assets/fonts/CircularSpotifyText-Bold.woff2") format("woff2"),
    url("../assets/fonts/CircularSpotifyText-Bold.woff") format("woff"),
    url("../assets/fonts/CircularSpotifyText-Bold.svg#CircularSpotifyText")
      format("svg");
}

@font-face {
  font-family: "Circular Spotify Text";
  font-weight: 400;
  font-style: normal;

  src: url("../assets/fonts/CircularSpotifyText-Book.woff2") format("woff2"),
    url("../assets/fonts/CircularSpotifyText-Book.woff") format("woff"),
    url("../assets/fonts/CircularSpotifyText-Book.svg#CircularSpotifyText")
      format("svg");
}

@font-face {
  font-family: "Circular Spotify Text";
  font-weight: 300;
  font-style: normal;

  src: url("../assets/fonts/CircularSpotifyText-Light.woff2") format("woff2"),
    url("../assets/fonts/CircularSpotifyText-Light.woff") format("woff"),
    url("../assets/fonts/CircularSpotifyText-Light.svg#CircularSpotifyText")
      format("svg");
}

@font-face {
  font-family: "Circular Spotify Text";
  font-weight: 600;
  font-style: normal;

  src: url("../assets/fonts/CircularSpotifyText-Medium.woff2") format("woff2"),
    url("../assets/fonts/CircularSpotifyText-Medium.woff") format("woff"),
    url("../assets/fonts/CircularSpotifyText-Medium.svg#CircularSpotifyText")
      format("svg");
}

@font-face {
  font-family: "Circular Spotify Black";
  font-weight: normal;
  font-style: normal;

  src: url("../assets/fonts/CircularSpotifyHead-Black.woff2") format("woff2"),
    url("../assets/fonts/CircularSpotifyHead-Black.woff") format("woff"),
    url("../assets/fonts/CircularSpotifyHead-Black.svg#CircularSpotifyHead")
      format("svg");
}
